import React from "react"
import SectionPageMargin from '../Layout/Section/SectionPageMargin'
import Grid2 from '../Layout/Grid2/Grid2'
import GridItem from '../Layout/GridItem/GridItem'
import FlexCenter from '../Layout/Flex/FlexCenter'
import ImageBox from '../Layout/ImageBox/ImageBox'
import {useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Heading2 from '../Type/Heading2'
import Paragraph1 from '../Type/Paragraph1'

const HowItWorks = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "summon-pricing.png" }) {
        childImageSharp {
          fluid(maxWidth: 406) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
      <SectionPageMargin>
        <Grid2>
          <GridItem>
            <Heading2>How it works</Heading2>
            <Paragraph1>
            Summon is a full concierge service for selling your car. 
            We will collect, clean, professionally photograph and list your car for sale, field phone calls and enquiries, 
            collect the car and take the test drives, negotiate and provide a safe and reliable funds transfer to you. 
            We’ll also help with the Roadworthy / Safety check and transfer of ownership.
            </Paragraph1>
          </GridItem>
          <GridItem>
            <FlexCenter>
                <ImageBox minSize="300px" maxSize="406px">
                  <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                </ImageBox>
            </FlexCenter>
          </GridItem>
        </Grid2>
    </SectionPageMargin>
    )
}


export default HowItWorks