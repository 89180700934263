import React from "react"
import SectionPageMargin from '../Layout/Section/SectionPageMargin'
import { navigate } from "gatsby"
import styled from 'styled-components'
import tickBullet from './tick.png'
import crossBullet from './cross.png'
import badgeIcon from './badge.png'
import Button2 from '../Layout/Button/Button2'

const SummonVs = () => {

  const handleClick = () => {
    navigate('https://value.summon.com.au/');
  };

    return (
      <SectionPageMargin bgColor="#3175a9" marginTop="0px">
        <SummonVsContainer>
         
          <SellBox topBorderColor="#68a0be" bgColor="#39638f" badge="">
            <Badge />
            <Heading color="#a5dbea">Sell with Summon</Heading>
            <SubHeading>We advertise and sell <br/> your car for you</SubHeading>
            <List>
              <Tick>You get a great price</Tick>
              <Tick>You keep your car until sold</Tick>
              <Tick>We take photos & create your ad</Tick>
              <Tick>We collect the car from your home</Tick>
              <Tick>Leave the test-drives to us</Tick>
              <Tick>We handle the $$ and rego</Tick>
            </List>
            <Button2 bgColor="#a5dbea" style={{color: "black"}} onClick={handleClick}>Enquire Now</Button2>
          </SellBox>

          <Vs>VS</Vs>

          <SellBox topBorderColor="#c5e5d2">
            <Heading>Sell to a Dealer</Heading>
            <SubHeading>You sell your car or trade-in to a dealer at a wholesale price</SubHeading>
            <List>
              <Tick>Your car is sold quickly</Tick>
              <Tick>The dealer helps with paperwork</Tick>
              <Cross>Lack of price transparency</Cross>
              <Cross>You don’t get the best price</Cross>
            </List>
          </SellBox>

          <SellBox topBorderColor="#45204f"  >
            <Heading>Sell privately</Heading>
            <SubHeading>You advertise and sell your car yourself </SubHeading>
            <List>
              <Tick>No dealer fees or commissions</Tick>
              <Cross>You take photos and create ads</Cross>
              <Cross>You field time-wasting calls</Cross>
              <Cross>You organise and do test-drives</Cross>
              <Cross>You try to get money from buyer</Cross>
              <Cross>You do the paperwork yourself</Cross>
            </List>
          </SellBox>

        </SummonVsContainer>
      </SectionPageMargin>
    )
}


export default SummonVs

const SummonVsContainer = styled.div`
  display: grid;
  column-gap: 40px;
 
  margin-bottom: 40px;
  padding-bottom: 40px;
  align-items: center;
  grid-template-columns: 1fr 40px 1fr 1fr;

  @media (max-width: 800px) {
    justify-content: center;
    grid-template-columns: 80%;
    grid-template-rows: auto auto auto auto;
    row-gap: 100px;
  }

`;

const SellBox = styled.div.attrs(props => ({
  bgColor: props.bgColor || '#3479ae'
}))`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px #c7edfb solid;
  border-top: 10px solid ${props => props.topBorderColor};
  background:  ${props => props.bgColor};
  padding: 10px;
  text-align: center;
  margin: 2px;

`;

const Vs = styled.div`
  color: white;
  text-align: center;
  font-weight: 600;
  min-width: 60px;

`;

const Heading = styled.h4.attrs(props => ({
  color: props.color || 'white'
}))`
  color:  ${props => props.color};
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 0px;
  margin-bottom: 3px;  
`;

const SubHeading = styled.p`
  color: white; 
  margin-top: 0px;
  margin-bottom: 0px;  
`;

const List = styled.ul`
  color: white; 
  text-align: left;
  margin-bottom: 30px;
`;

const Tick = styled.li`

  margin-left: -30px;
  padding: 5px;
  list-style: none;
  margin-bottom: 10px;
  font-size: 0.9rem;
  line-height: 1rem;
  position: relative;
  padding-left: 40px;
  color: white;

  &:before {
    content: '';
    width: 31px;
    height: 31px;
    position: absolute;
    background-image: url('${tickBullet}');
    background-size: cover;
    background-position: center;
    left: 0;
    top: 0px;
  }


`;

const Cross = styled.li`

  margin-left: -30px;
  padding: 5px;
  list-style: none;
  margin-bottom: 10px;
  line-height: 1rem;
  font-size: 0.9rem;
  position: relative;
  padding-left: 40px;
  color: white;

  &:before {
    content: '';
    width: 31px;
    height: 31px;
    position: absolute;
    background-image: url('${crossBullet}');
    background-size: cover;
    background-position: center;
    left: 0;
    top: 0px;
  }

`;

const Badge = styled.div`

  width: 56px;
  height: 74px;
  position: absolute;
  background-image: url('${badgeIcon}');
  background-size: cover;
  background-position: center;
  right: -15px;
  top: -25px;

`;







