import React from "react"
import styled from 'styled-components'

const ImageBox = ({ size, minSize, maxSize, marginTop, children }) => {
    if(!size) size = "300px";
    if(!maxSize) maxSize = size;
    if(!minSize) minSize = size;
    if(!marginTop) marginTop = "60px";    
   
  return (
    <>
        <Box maxSize={maxSize} minSize={minSize} marginTop={marginTop}>
            {children}
        </Box>
    </>
  )
} 

const Box = styled.div`
  margin-top: ${props => props.marginTop};
  width: 100%;
  max-width: ${props => props.maxSize};
  min-width: ${props => props.minSize};
  @media (max-width: 800px) {
    margin-top: ${props => props.marginTop/2}px;
  }

`
export default ImageBox


//width: ${props => props.size};