import React from "react"
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SummonVs from '../components/SummonVs/SummonVs'
import HowItWorks from '../components/HowItWorks/HowItWorks'
import ReadyToGetStarted from '../components/ReadyToGetStarted/ReadyToGetStarted'
import SEO from "../components/Seo/Seo"
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import Heading3 from '../components/Type/Heading3'
import Paragraph1 from '../components/Type/Paragraph1'
import Grid2 from '../components/Layout/Grid2/Grid2'
import GridItem from '../components/Layout/GridItem/GridItem'
import FlexCenter from '../components/Layout/Flex/FlexCenter'
import ImageBox from '../components/Layout/ImageBox/ImageBox'
import Comparison from '../images/assets/comparison.svg'
import StyledLink from '../components/Type/StyledLink'


const SellWithSummon = ({ location, data }) => 
  (
  <Layout>

    <SEO  title="Sell your car with Summon" 
          description="By selling your prestige car with Summon’s unique concierge service, 
          you can get the best of both worlds. The ease of selling to a dealer and the value 
          of selling privately."
          location={location}
    />

    <PageHeader 
      title="Sell your car with Summon"
      text="By selling your prestige car with Summon’s unique concierge service, 
      you can get the best of both worlds. The ease of selling to a dealer and the value 
      of selling privately." 
      marginBottom="0px"
      />

      <SummonVs />

      <HowItWorks />

      <SectionPageMargin>
        <Grid2>
          <GridItem>
            <Heading3>Sell with Summon, or to a Dealer?</Heading3>
            <Paragraph1>
              For the longest time, prestige car owners have had two choices for selling their car; 
              sell to a Dealer (which is fast and convienient, but you don’t get the best price) or 
              sell privately (which can take a long time and you have to deal with all the hassles).
            </Paragraph1>
            <Paragraph1>
              Finally there is a third choice. Summon does all the hard work for you, and 
              provides you with a great sale.
            </Paragraph1>

            <StyledLink to="/enquiry/">Ready to get started? Tell us about your car</StyledLink>

          </GridItem>
          <GridItem>
            <FlexCenter>
             
                <ImageBox size="90%">
                  <Comparison />
                </ImageBox>
                <sup style={{padding: '20px'}}>
                    1 Based on average difference to private seller price 
                    from Summon research, varies depending on trade-in vs 
                    wholesale prices. We recommend getting a trade-in car valuation 
                     from a trusted dealer.
                </sup>
                   
            </FlexCenter>
          </GridItem>
        </Grid2>
    </SectionPageMargin>
      
    <ReadyToGetStarted />

  </Layout>
)

export default SellWithSummon
